import React, { Component } from 'react';
import LoginLink from './LoginLink'
import './App.css';

const apiDomain = localStorage.getItem('apiDomain') || 'https://accounts.dstatistics.com'
const customerId = '00000000-0000-0000-0000-000000000000'
const discoveryURL = `${apiDomain}/${customerId}/login/.well-known/openid-configuration`
const clientId = localStorage.getItem('clientId') || '0436022a-87ef-42f2-93d2-43ba25bdd8e1'

function checkForCodeAndState() {
  const params = new URLSearchParams(window.location.search)
  const code = params.get('code')
  const state = params.get('state')
  params.delete('code')
  params.delete('state')
  const newUrl = window.location.href.replace(window.location.search, params.toString())
  window.history.replaceState({path: newUrl}, '', newUrl)
  return {
    code,
    state
  }
}

async function codeForTokens(tokenEndpoint, code) {
  const data = new URLSearchParams();
  data.append('grant_type', 'authorization_code');
  data.append('code', code);
  data.append('redirect_uri', 'https://' + window.location.host);
  data.append('code_verifier', sessionStorage.getItem('code_verifier'));
  data.append('client_id', clientId)
  const req = {
      method: 'POST',
      body: data,
  };
  return fetch(tokenEndpoint, req).then(x => x.json())
}

async function fetchProfile(userinfoEndpoint, tokens) {
  let headers = new Headers();
  headers.append("Authorization", `${tokens.token_type} ${tokens.access_token}`);
  return fetch(userinfoEndpoint, { headers }).then(res => res.json())
}

function AuthMessage(profile, tokens) {
  if (!tokens || !profile) return 'Please log in to access more content'
  const idToken = JSON.parse(atob(tokens.id_token.split('.')[1]))
  const loginTime = new Date(idToken.auth_time * 1000)
  return `Welcome ${profile.preferred_username}! You last authenticated at ${loginTime.toLocaleTimeString()} on ${loginTime.toLocaleDateString()}.`
}

class App extends Component {
  state = {
    oidcConfig: null,
    profile: JSON.parse(sessionStorage.getItem('profile')),
    tokens: JSON.parse(sessionStorage.getItem('tokens')),
    mounted: false
  }

  async componentDidMount() {
    if (this.state.profile) {
      this.setState({
        ...this.state,
        mounted: true
      })
      return
    }
    const oidcConfig = await fetch(discoveryURL).then(x => x.json())
    const { code, state } = checkForCodeAndState()
    if (code !== null) {
      const tokenResponse = await codeForTokens(oidcConfig.token_endpoint, code)
      sessionStorage.setItem('tokens', JSON.stringify(tokenResponse))
    }
    let profile = this.state.profile
    const tokens = this.state.tokens || JSON.parse(sessionStorage.getItem('tokens'))
    if (tokens && !profile) {
      profile = await fetchProfile(oidcConfig.userinfo_endpoint, tokens)
      sessionStorage.setItem('profile', JSON.stringify(profile))
    }
    this.setState({ oidcConfig, profile, tokens, mounted: true })
  }

  logout = (e) => {
    e.preventDefault()
    sessionStorage.removeItem('tokens')
    sessionStorage.removeItem('profile')
    this.setState({ ...this.state, profile: null, tokens: null })
  }

  render() {
    const { oidcConfig, profile, tokens, mounted } = this.state
    // let idToken = null
    // if (tokens) {
    //   idToken = JSON.parse(atob(tokens.id_token.split('.')[1]))
    //   console.log(idToken)
    // }
    const loginLink = oidcConfig ? (
      <div>
        <LoginLink
          authorizationEndpoint={oidcConfig.authorization_endpoint}
          clientId={clientId}
          prompt={localStorage.getItem('prompt')}>Login</LoginLink>
      </div>
    ) : (
      <span>Loading...</span>
    )
    const authButton = profile ? <button className="btn btn-primary" onClick={this.logout}>Logout</button> : loginLink
    // const loginTime = new Date(idToken.auth_time * 1000)
    const authMessage = AuthMessage(profile, tokens)
    const content = profile ? (
      <div>
        <h2>Personalized content!</h2>
        <p>Isn't this exciting?</p>
        <em>TODO: Add some exciting content</em>
        <p>Here's your profile, that's exciting right?</p>
        <pre className="profile">{JSON.stringify(profile, null, '\t')}</pre>
      </div>
    ) : (
      <div>
        <h2>Some Content</h2>
        <p>This is just boring generic conent. You should really log in if you want anything special.</p>
        <p>Est ut sit culpa ex. Ut commodo labore anim in ullamco sunt amet. Commodo do laboris occaecat veniam nulla officia sit proident. Laboris anim aliqua voluptate sit mollit amet proident laboris voluptate sint reprehenderit. Do minim amet exercitation deserunt duis voluptate nulla minim qui sunt deserunt nostrud elit sunt. Eu cupidatat laboris incididunt aute ullamco tempor. Fugiat velit anim est incididunt ad veniam.</p>
      </div>
    )
    const appContent = mounted ? (
      <div>
        <header className="navbar navbar-expand navbar-light bg-light">
          <a href="#" className="mavbar-brand mr-auto">ACME Corp</a>
          <span className="navbar-text">{authMessage}</span>
          <div className="auth-buttons">
            {authButton}
          </div>
        </header>
        <div className="container">
          {content}
        </div>
      </div>
    ) : (
      <div className="app-loading">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading ACME Corp...</span>
        </div>
        <h2>Loading ACME Corp...</h2>
      </div>
    )
    return (
      <div className="App">
        {appContent}
      </div>
    )
  }
}

export default App;
