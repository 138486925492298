import React, { Component } from 'react';

export default class LoginLink extends Component {
  state = {
    authUrl: null
  }

  randomString(len) {
    let arr = new Uint8Array((len || 32) / 2)
    window.crypto.getRandomValues(arr)
    let hexer = x => ('0' + x.toString(16)).substr(-2)
    return Array.from(arr, hexer).join('')
  }

  async makePKCE() {
    const b64replacements = { '+': '-', '/': '_', '=': '' };
    let v = this.randomString();
    let encoder = new TextEncoder();
    let data = encoder.encode(v);
    let s256 = await window.crypto.subtle.digest('SHA-256', data);
    let bytes = new Uint8Array(s256);
    let b64 = window.btoa(String.fromCharCode(...bytes));
    let c = b64.replace(/[+/=]/g, x => b64replacements[x]);
    return {
        verifier: v,
        challenge: c,
    };
  }

  async makeAuthURL() {
    const pkce = await this.makePKCE()
    const state = this.props.state || this.randomString()
    const params = {
      state,
      code_challenge: pkce.challenge,
      code_challenge_method: "S256",
      client_id: this.props.clientId,
      redirect_uri: window.location.protocol + '//' + window.location.host,
      response_type: "code",
      scope: "openid profile",
    }
    if (this.props.prompt) {
      params['prompt'] = this.props.prompt
    }
    const qsParams = Object.entries(params)
      .reduce((acc, next) => [...acc, `${encodeURIComponent(next[0])}=${encodeURIComponent(next[1])}`], [])
      .join('&')
    window.sessionStorage.setItem("state", state);
    window.sessionStorage.setItem("code_verifier", pkce.verifier);
    return `${this.props.authorizationEndpoint}?${qsParams}`
  }

  async componentDidMount() {
    const authUrl = await this.makeAuthURL()
    this.setState({ authUrl })
  }

  render() {
    const { authUrl } = this.state
    return authUrl ? (
      <a className="btn btn-primary btn-lg" href={authUrl}>{this.props.children}</a>
    ) : (
      null
    )
  }
}
